import React from 'react'
import logobot from '../Assets/logo_bot2.png'
import logotop from '../Assets/logo_top2.png'
import './Greets.css'

const Greets = () => {
  return (
    <div className='greets-main'>
        <div ><img src={logotop} alt='' className="greets-main-logotop"></img></div>
        <div className="greets-main-title">CustomControlz</div>
        <div className="greets-main-punchline">Fine French Tools</div>
        <div ><img src={logobot}  alt='' className="greets-main-logobot"></img></div>
        <div className="greets-product-main">Our recipes</div>
        <div className="greets-product-list"><a href="https://www.levampe.com">LeVampe-Orginal</a></div>
        <div className="greets-product-list">(incoming project)</div>
        <div className="greets-product-list">(incoming project)</div>
    </div>
  )
}

export default Greets